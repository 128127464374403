<!-- 매체 등록/수정 -->
<template>
  <v-container
    id="register"
    fluid
    tag="section">
    <v-row
            align="center"
            justify="center">
      <v-col cols="12">
      <base-material-card
              color="green">
        <template v-slot:heading>
          <h1 class="display-2 font-weight-regular">
            <span v-if="vModel.data.kor_name">
              {{ vModel.data.kor_name }} >
            </span>
            매체 {{mediaType}}
          </h1>
          <div class="subtitle-1 font-weight-regular">
          매체를 {{mediaType}}하세요.
        </div>
      </template>
        <v-card-text>
          <h2 style="padding: 20px 10px;">
            매체 정보 {{mediaType}}
          </h2>
          <v-form
                  v-model="vModel.valid"
                  ref="form">
            <v-row class="pl-5 pr-5"
                    align="center">
              <v-col cols="1" class="text-right grey--text">
                매체명
              </v-col>
              <v-col cols="5">
                <v-text-field
                        placeholder="매체명"
                        type="text"
                        name="kor_name"
                        color="secondary"
                        v-model="vModel.data.kor_name"
                        :rules="config.mediaKorNameRules"
                        :disabled="$route.name === 'mediaEdit'"
                        required />
              </v-col>
              <v-col cols="1" class="text-right grey--text">
                호스트명
              </v-col>
              <v-col cols="5">
                <v-text-field
                        placeholder="호스트명"
                        type="text"
                        name="name"
                        color="secondary"
                        v-model="vModel.data.name"
                        :rules="config.mediaNameRules"
                        required />
              </v-col>
            </v-row>
            <v-row class="pl-5 pr-5"
                    align="center">
              <v-col cols="1" class="text-right grey--text">
                미디어 번호
              </v-col>
              <v-col cols="5">
                <v-text-field
                        placeholder="미디어 번호"
                        type="number"
                        name="site3_no"
                        color="secondary"
                        v-model="vModel.data.site3_no" />
              </v-col>
              <v-col cols="1" class="text-right grey--text">
                정산
              </v-col>
              <v-col cols="5">
                <v-select :items="config.revenueSettlementOptions"
                          v-model="vModel.data.rs_type"
                          placeholder="정산 방식"
                          color="secondary" />
                <v-text-field
                        :placeholder="commissionPlaceholder()"
                        type="number"
                        name="commission"
                        color="secondary"
                        v-model="vModel.data.commission"
                        :rules="config.commissionRules"
                        required />
                <p v-if="vModel.data.rs_type === 'cpm'">
                  * CPM 정산 요율 변경 시 <strong>다음날 부터 반영</strong>됩니다.
                  <br />반영 시점 조정 필요 시 개발팀 문의바랍니다.
                </p>
                <p v-else>
                  * 현재 수익쉐어 정산 요율 변경 시 <strong>즉시 반영</strong>되며,
                  <strong>최근 3~4일 전 수익도 변경된 요율 기준으로 재반영</strong>됩니다.
                </p>
                <p>
                  * 현재 정상 방식이 변경되는(수익쉐어 방식 &lt;=&gt;  CPM 정산) 경우는 고려되지 않았습니다.
                </p>
              </v-col>
            </v-row>
            <v-row class="pl-5 pr-5"
                    align="center">
              <v-col cols="1" class="text-right grey--text">
                이름
              </v-col>
              <v-col cols="5">
                <v-text-field
                        placeholder="이름"
                        type="text"
                        name="user_name"
                        color="secondary"
                        v-model="vModel.data.user.name"
                        :rules="config.nameRules"
                        :disabled="$route.name === 'mediaEdit'"
                        required />
              </v-col>
              <v-col cols="1" class="text-right grey--text">
                계정 이메일
              </v-col>
              <v-col cols="5">
                <v-text-field
                        placeholder="계정 이메일"
                        type="email"
                        name="email"
                        color="secondary"
                        ref="email"
                        v-model="vModel.data.user.email"
                        :rules="config.emailRules"
                        @blur="emailCheck"
                        required />
              </v-col>
            </v-row>
            <v-row class="pl-5 pr-5"
                    align="center">
              <v-col cols="1" class="text-right grey--text">
                비밀번호
              </v-col>
              <v-col cols="5"
                     v-if="$route.name === 'mediaRegister'">
                <v-text-field
                        placeholder="비밀번호"
                        type="password"
                        name="password"
                        color="secondary"
                        v-model="vModel.data.user.password"
                        :rules="config.passwordRules"
                        required />
              </v-col>
              <v-col cols="5" v-else>
                <v-text-field
                        placeholder="비밀번호"
                        type="password"
                        name="password"
                        color="secondary"
                        v-model="vModel.data.user.password" />
              </v-col>
              <v-col cols="1" class="text-right grey--text">
                비밀번호 확인
              </v-col>
              <v-col cols="5" v-if="$route.name === 'mediaRegister'">
                <v-text-field
                        placeholder="비밀번호 확인"
                        type="password"
                        name="password2"
                        color="secondary"
                        v-model="vModel.data.user.passwordConfirmation"
                        :rules="config.passwordConfirmationRules"
                        required />
              </v-col>
              <v-col cols="5" v-else>
                <v-text-field
                        placeholder="비밀번호 확인"
                        type="password"
                        name="password2"
                        color="secondary"
                        v-model="vModel.data.user.passwordConfirmation" />
              </v-col>
            </v-row>
            <v-row class="pl-5 pr-5"
                    align="center">
              <v-col cols="1" class="text-right grey--text">
                대표자
              </v-col>
              <v-col cols="5">
                <v-text-field
                        placeholder="대표자"
                        type="text"
                        name="ceo"
                        color="secondary"
                        v-model="vModel.data.company.ceo" />
              </v-col>
              <v-col cols="1" class="text-right grey--text">
                주소
              </v-col>
              <v-col cols="5">
                <v-text-field
                        placeholder="주소"
                        type="text"
                        name="address"
                        color="secondary"
                        v-model="vModel.data.company.address" />
              </v-col>
            </v-row>
            <v-row class="pl-5 pr-5"
                    align="center">
              <v-col cols="1" class="text-right grey--text">
                사업자등록번호
              </v-col>
              <v-col cols="5">
                <v-text-field
                        placeholder="사업자 등록번호"
                        type="text"
                        name="businessNo"
                        v-mask="businessNoMask"
                        color="secondary"
                        v-model="vModel.data.company.businessNo" />
              </v-col>
              <v-col cols="1" class="text-right grey--text">
                전화번호
              </v-col>
              <v-col cols="5">
                <v-text-field
                        placeholder="전화번호"
                        type="text"
                        name="managerPhone"
                        v-mask="managerPhoneMask"
                        color="secondary"
                        v-model="vModel.data.company.managerPhone" />
              </v-col>
            </v-row>
            <v-row class="pl-5 pr-5"
                    align="center">
              <v-col cols="1" class="text-right grey--text">
                담당자명
              </v-col>
              <v-col cols="5">
                <v-text-field
                        placeholder="담당자명"
                        type="text"
                        name="managerName"
                        color="secondary"
                        v-model="vModel.data.company.managerName" />
              </v-col>
              <v-col cols="1" class="text-right grey--text">
                담당자 이메일
              </v-col>
              <v-col cols="5">
                <v-text-field
                        placeholder="담당자 이메일"
                        type="email"
                        name="managerEmail"
                        color="secondary"
                        v-model="vModel.data.company.managerEmail" />
              </v-col>
            </v-row>
            <v-row class="pl-5 pr-5"
                    align="center">
              <v-col cols="1" class="text-right grey--text">
                은행명
              </v-col>
              <v-col cols="5">
                <v-text-field
                        placeholder="은행명"
                        type="text"
                        name="bankName"
                        color="secondary"
                        v-model="vModel.data.company.bankName" />
              </v-col>
              <v-col cols="1" class="text-right grey--text">
                계좌번호
              </v-col>
              <v-col cols="5">
                <v-text-field
                        placeholder="계좌번호"
                        type="number"
                        name="bankAccount"
                        color="secondary"
                        v-model="vModel.data.company.bankAccount" />
              </v-col>
            </v-row>
            <v-row class="pl-5 pr-5"
                    align="center">
              <v-col cols="1" class="text-right grey--text">
                세금계산서<br>발행 이메일
              </v-col>
              <v-col cols="5">
                <v-text-field
                        placeholder="세금계산서 발행 이메일"
                        type="email"
                        name="taxEmail"
                        color="secondary"
                        v-model="vModel.data.company.taxEmail" />
              </v-col>
              <v-col cols="1" class="text-right grey--text">
                정산 API
              </v-col>
              <v-col cols="5">
                http://api.aiinfeed.com/publisher/report/{{ vModel.data.placement_id }}/revenue?key={{ vModel.data.hash_key }}&startDate={startDate}&endDate={endDate}}
                <br><br>
                <div>{startDate}, {endDate} format : 2020-07-01</div>
              </v-col>
            </v-row>

            <v-row class="pl-5 pr-5"
                   align="center">
              <v-col
                cols="1"
                class="text-right grey--text">
                제휴 상태
              </v-col>
              <v-col cols="5">
                <v-select :items="config.contractStatusOptions"
                          v-model="vModel.data.contract_status_id"
                          placeholder="제휴 상태"
                          color="secondary" />
              </v-col>
            </v-row>

            <v-row class="pl-5 pr-5"
                   align="center">
                <v-col
                        cols="1"
                        class="text-right grey--text">
                  댓글 설정
                </v-col>
                <v-col cols="5">
                  <v-radio-group
                          row
                          v-model="vModel.data.allowComment">
                   <v-radio
                            label="허용"
                            :value="true"/>
                    <v-radio
                            label="차단"
                            :value="false"/>
                  </v-radio-group>
                </v-col>
              </v-row>
            <v-row class="pl-5 pr-5"
                   v-if="response.partnerList">
                <v-col cols="1"
                        class="text-right grey--text">
                  CP 구독 신청
                </v-col>
                <v-col cols="5">
                  <div style="max-height:350px;overflow-y: auto;border: solid 1px #ddd;border-width: 1px 0;">
                    <v-data-table
                            style="width:100%"
                            :headers="config.headers"
                            :items="response.partnerList"
                            :items-per-page="Number(response.partnerList.length)"
                            hide-default-footer>
                      <template v-slot:item="{ item }">
                        <tr>
                          <td class="text-center">{{item.id}}</td>
                          <td class="text-center">{{item.name}}</td>
                          <td class="text-center">
                            <v-btn @click="linkContentsCP(item)" small rounded color="warning">
                              {{numReplace(item.totalContents)}}
                            </v-btn>
                          </td>
                          <!--구독중인 cp 있을때-->
                          <template v-if="response.media.linkPartner.length > 0">
                            <td class="text-center">
                              <!--구독 미신청-->
                              <template v-if="resultLinkPartners(item)">
                                -
                              </template>
                              <!--구독 신청/구독중-->
                              <template v-else>
                                <span v-if="resultLinkPartners2(item) === 0">신청중</span>
                                <span v-else>구독중</span>
                              </template>
                            </td>
                            <td class="text-center">
                              <!--구독 미신청-->
                              <template v-if="resultLinkPartners(item)">
                                <v-btn @click="postLinkPartners(item)" small rounded color="success">구독하기</v-btn>
                              </template>
                              <!--구독 신청/구독중-->
                              <template v-else>
                                <v-btn @click="deleteLinkPartners(item)" small rounded color="waring">구독취소</v-btn>
                              </template>
                            </td>
                          </template>
                          <!--구독중인 cp 없을때-->
                          <template v-else>
                            <td class="text-center">
                              -
                            </td>
                            <td class="text-center">
                              <v-btn @click="postLinkPartners(item)" small rounded color="success">구독하기</v-btn>
                            </td>
                          </template>
                        </tr>
                      </template>
                    </v-data-table>
                  </div>
                </v-col>
              </v-row>
            <template v-if="response.category">
              <h2 style="padding: 20px 10px;">
                카테고리 허용
              </h2>
              <template v-if="config.acceptCategories.length > 0">
              <div
                  class="d-inline-flex"
                  style="width:18%;margin-right:2%;"
                  v-for="val in config.acceptCategories"
                  :key="val.header.id">
                <v-data-table
                        style="width:100%"
                        :headers="val.header"
                        :items="val.selections"
                        :items-per-page="Number(val.selections.length)"
                        hide-default-footer
                        show-select
                        v-model="acceptCategoriesData">
                  <template v-slot:item.name="{ item }">
                    <span class="category-list" @click="linkContentsCategory(val.header[0].id, item.id)">
                      {{ item.name }} ({{item.contents_count}})
                    </span>
                  </template>
                </v-data-table>
              </div>
              </template>
            </template>
            <v-row justify="center" class="mt-5">
              <template v-if="$route.name === 'mediaRegister'">
                <v-btn
                    :disabled="!vModel.valid"
                    class="mt-5"
                    color="success"
                    @click="submitRegister">
                    등록
                </v-btn>
              </template>
              <template v-else>
                <v-btn
                        class="mt-5"
                        color="default"
                        @click="beforePage">
                  취소
                </v-btn>
                <v-btn
                        :disabled="!vModel.valid"
                        class="mt-5"
                        color="success"
                        @click="submitEdit">
                  수정
                </v-btn>
              </template>
            </v-row>
          </v-form>
        </v-card-text>
      </base-material-card>
      </v-col>
    </v-row>
    <base-material-snackbar
      v-model="vModel.snackbar"
      :type="vModel.snackbarType"
      v-bind="{
        'top': true,
        'right': true
      }">
      <span style="display:block;width:300px;margin:0;">{{vModel.errorMessage}}</span>
    </base-material-snackbar>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { common } from '../../mixins/common.js'

export default {
  mixins: [ common ],
  created () {
    this.refresh()
  },
  data () {
    return {
      response: {
        register: null,
        edit: null,
        category: null,
        partner: null,
        partnerList: null,
        postLink: null,
        deleteLink: null,
        adult: []
      },
      vModel: {
        valid: true,
        data: {
          name: '',
          kor_name: '',
          allowComment: true,
          commission: null,
          user: {
            name: '',
            email: '',
            password: '',
            passwordConfirmation: ''
          },
          company: {
            ceo: '',
            address: '',
            businessNo: '',
            taxEmail: '',
            managerName: '',
            managerPhone: '',
            managerEmail: '',
            bankName: '',
            bankAccount: '',
          },
          acceptCategories: [],
          partners: '',
          contract_status_id: 1
        },
        snackbar: false,
        snackbarType: 'warning',
        errorMessage: null,
      },
      acceptCategoriesData: [],
      config: {
        headers: [
          { text: 'ID', value: 'id', align: 'center' },
          { text: 'CP명', value: 'name', align: 'center' },
          { text: '컨텐츠 갯수', value: 'totalContents', align: 'center' },
          { text: '상태', value: 'status', align: 'center' },
          { text: '버튼', value: 'btn', align: 'center' },
        ],
        partnersOptions: [],
        linkPartners: null,
        nameRules: [ v => !!v || '이름을 입력하세요.' ],
        emailRules: [
          v => !!v || '계정 이메일을 입력하세요.',
          v => /.+@.+\..+/.test(v) || '이메일 형식에 맞게 입력하세요.'
        ],
        passwordRules: [ v => !!v || '비밀번호를 입력하세요.',
          v => v.length > 5 || '비밀번호를 6자 이상 입력하세요.' ],
        passwordConfirmationRules: [
          v => !!v || '비밀번호를 재입력하세요.',
          v => this.vModel.data.user.password === v || '입력한 비밀번호와 같은 값을 입력하세요.' ],
        mediaKorNameRules: [ v => !!v || '매체명을 입력하세요.' ],
        mediaNameRules: [ v => !!v || '호스트명을 입력하세요.' ],
        managerNameRules: [ v => !!v || '담당자명를 입력하세요.' ],
        site3NoRules: [ v => !!v || '미디어 번호를 입력하세요.' ],
        managerPhoneRules: [ v => !!v || '전화번호를 입력하세요.',
          v => v.length > 9 || '전화번호를 10자 이상 입력하세요.' ],
        managerEmailRules: [ v => !!v || '담당자 이메일을 입력하세요.',
          v => /.+@.+\..+/.test(v) || '이메일 형식에 맞게 입력하세요.' ],
        ceoRules: [ v => !!v || '대표자명을 입력하세요.' ],
        addressRules: [ v => !!v || '주소를 입력하세요.' ],
        commissionRules: [v => {
          let rsType = this.vModel.data.rs_type
          if (rsType === 'cpm') {
            return !!v || 'CPM 정산 단가를 입력하세요.'
          } else { // if (rsType === 'rs') {
            return !!v || '정산율을 입력하세요.'
          }
        },
        v => {
          let rsType = this.vModel.data.rs_type
          if (rsType === 'cpm') {
            return Number(v) < 10001 || 'CPM 정산 단가는 10,000을 초과할 수 없습니다.'
          } else { // if (rsType === 'rs') {
            return Number(v) < 101 || '정산율은 100을 초과할 수 없습니다.'
          }
        }],
        businessNoRules: [ v => !!v || '사업자 등록 번호를 입력하세요.' ],
        taxEmailRules: [ v => !!v || '세금계산서를 발행 받을 이메일을 입력하세요.',
          v => /.+@.+\..+/.test(v) || '이메일 형식에 맞게 입력하세요.' ],
        bankNameRules: [ v => !!v || '은행명 입력하세요.' ],
        bankAccountRules: [ v => !!v || '계좌번호를 숫자만 입력하세요.',
          v => v.length > 9 || '계좌번호를 10자 이상 입력하세요.' ],
        acceptCategories: [],
        contractStatusOptions: [
          // { value: 'all', text: '전체' },
          { value: 1, text: '등록' },
          { value: 2, text: '준비 중' },
          { value: 3, text: '제휴 중' },
          { value: 4, text: '일시 정지' },
          { value: 6, text: '보류' },
          { value: 5, text: '중단' }
        ],
        revenueSettlementOptions: [
          { value: 'rs', text: '기본 정산 (수익웨어 방식)' },
          { value: 'cpm', text: 'CPM 정산' },
        ]
      },
    }
  },
  methods: {
    ...mapActions(['xhttp']),
    snack (type, val) {
      this.vModel.errorMessage = val
      this.vModel.snackbarType = type
      this.vModel.snackbar = true
    },
    /* 페이지 로드시 */
    refresh () {
      let name = this.$route.name
      if (name === 'mediaEdit') {
        this.getMedia()
        this.getPartners()
      }
    },
    /* cp구독신청에서 컨텐츠 갯수 클릭시 */
    linkContentsCP (val) {
      this.$router.push({ name: 'contentsList', query: { partner: val.key } })
    },
    /* 카테고리 리스트 클릭시 */
    linkContentsCategory (id, val) {
      this.$router.push({ name: 'contentsList', query: { type: id, category: val } })
    },
    /* cp 구독 신청하기 */
    postLinkPartners (val) {
      if (confirm('신청하겠습니까?')) {
        this.xhttp({
          url: '/' + this.$route.params.media_id + '/link/' + val.key,
          method: 'post',
          data: null
        }).then((response) => {
          if (response.status === 200) {
            this.response.postLink = response.data.data
            this.snack('success', '구독 신청되었습니다.')
            this.getMedia()
          }
        }).catch((error) => {
          if (error.message === 'Request failed with status code 401') {
            this.$router.push({ 'name': 'UserLogin' })
          } else {
            this.snack('warning', '관리자에게 문의 바랍니다.')
          }
        })
      }
    },
    /* cp 구독 취소하기 */
    deleteLinkPartners (val) {
      if (confirm('취소하겠습니까?')) {
        this.xhttp({
          url: this.$route.params.media_id + '/link/' + val.key,
          method: 'delete',
          data: null
        }).then((response) => {
          if (response.status === 200) {
            this.response.deleteLink = response.data.data
            this.snack('success', '구독 취소되었습니다.')
            this.getMedia()
          }
        }).catch((error) => {
          if (error.message === 'Request failed with status code 401') {
            this.$router.push({ 'name': 'UserLogin' })
          } else {
            this.snack('warning', '관리자에게 문의 바랍니다.')
          }
        })
      }
    },
    /* 구독 신청 확인 */
    resultLinkPartners (val) {
      let list = this.config.linkPartners.filter(function ($value) {
        return $value.id === val.id
      })
      if (list.length > 0) {
        // 신청한거
        return false
      } else {
        // 신청안한거
        return true
      }
    },
    /* 구독 신청 중일때 해당값 return */
    resultLinkPartners2 (val) {
      let list = this.config.linkPartners.filter(function ($value) {
        return $value.id === val.id
      })
      return list[0].value
    },
    /* 매체 상세정보 가져오기 */
    getMedia () {
      let id = this.$route.params.media_id
      this.xhttp({
        url: '/media/' + id,
        method: 'get',
        params: null
      }).then((response) => {
        if (response.status === 200) {
          this.response.media = response.data.data.media
          this.vModel.data = this.response.media
          this.vModel.data.mode = 'default'
          if (!this.vModel.data.user) {
            this.vModel.data.user = { name: '' }
          }
          this.vModel.data.user.password = ''
          this.vModel.data.user.passwordConfirmation = ''
          if (!this.vModel.data.company) {
            this.vModel.data.company = {
              ceo: '',
              address: '',
              businessNo: '',
              taxEmail: '',
              managerName: '',
              managerPhone: '',
              managerEmail: '',
              bankName: '',
              bankAccount: '',
            }
          }
          this.config.linkPartners = null
          if (this.response.media.linkPartner.length > 0) {
            this.config.linkPartners = this.response.media.linkPartner.map(function (val) {
              return { id: val.partner_id, value: val.partner_approved }
            })
          }
          this.config.acceptCategories = []
          if (this.config.linkPartners) {
            this.postCategories()
          }
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    /* cp 리스트 가져오기 */
    getPartners () {
      let send = {
        per: 999,
        page: 1,
        orderby: 'name',
        direction: 'asc',
        contract_status_id: 'for_subscription',
        resolution: 'lo'
      }
      this.xhttp({
        url: '/partners',
        method: 'get',
        params: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.partner = response.data.data.partners
          this.config.partnersOptions = this.response.partner.map(function ($value) {
            return { value: $value.id, text: $value.name }
          })

          let list = this.response.partner
          let arr = []
          if (this.config.linkPartners) {
            /* 구독된 cp 먼저 arr에 넣기 */
            for (let i = 0; i < list.length; i++) {
              if (!this.resultLinkPartners(list[i])) {
                arr.push(list[i])
              }
            }
            /* 구독되지 않은 cp 를 arr 에 넣기 */
            for (let i = 0; i < list.length; i++) {
              if (this.resultLinkPartners(list[i])) {
                arr.push(list[i])
              }
            }
          } else {
            arr = list
          }
          this.response.partnerList = arr
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    /* 매체 등록하기 */
    submitRegister () {
      if (this.$refs.form.validate()) {
        if (confirm('등록 하시겠습니까?')) {
          let send = this.vModel.data
          send.commission = Number(send.commission)
          this.xhttp({
            url: '/media',
            method: 'post',
            data: send
          }).then((response) => {
            if (response.status === 201) {
              this.response.register = response.data.data
              this.snack('success', '매체 등록이 완료되었습니다.')
              this.$router.push({ name: 'mediaList' })
            }
          }).catch((error) => {
            if (error.message === 'Request failed with status code 401') {
              this.$router.push({ 'name': 'UserLogin' })
            } else {
              this.snack('warning', '관리자에게 문의 바랍니다.')
            }
          })
        }
      }
    },
    /* 매체 수정버튼 클릭 */
    submitEdit () {
      let id = this.$route.params.media_id
      if (this.$refs.form.validate()) {
        let password = this.vModel.data.user.password
        if (password) {
          if (password.length > 6) {
            let passwordConfirm = this.vModel.data.user.passwordConfirmation
            if (passwordConfirm) {
              if (password === passwordConfirm) {
                this.putUser(id)
              } else {
                this.snack('warning', '입력한 비밀번호와 같은 값을 입력하세요.')
              }
            } else {
              this.snack('warning', '비밀번호 확인을 입력하세요.')
            }
          } else {
            this.snack('warning', '비밀번호 6자 이상 입력하세요.')
          }
        } else {
          this.putUser(id)
        }
      }
    },
    /* 매체 수정하기 */
    putUser (id) {
      if (confirm('수정 하시겠습니까?')) {
        let send = this.vModel.data
        send.commission = Number(send.commission)
        this.xhttp({
          url: '/media/' + id,
          method: 'put',
          data: send
        }).then((response) => {
          if (response.status === 200) {
            this.response.edit = response.data.data
            this.snack('success', '매체 정보 수정 완료되었습니다.')
            this.beforePage()
          }
        }).catch((error) => {
          if (error.message === 'Request failed with status code 401') {
            this.$router.push({ 'name': 'UserLogin' })
          } else {
            this.snack('warning', '관리자에게 문의 바랍니다.')
          }
        })
      }
    },
    /* 이메일 input 벗어났을때 */
    emailCheck () {
      if (this.$route.name === 'mediaRegister') {
        let v = this.vModel.data.user.email
        // 이메일 형식일때
        if (/.+@.+\..+/.test(v)) {
          this.postEmailValidate()
        }
      }
    },
    /* 이메일 중복 체크 */
    postEmailValidate () {
      let send = {
        class: 'Media\\MediaStoreRequest',
        user: {
          email: this.vModel.data.user.email
        }
      }
      this.xhttp({
        url: '/validation',
        method: 'post',
        data: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.validation = response.data
          if (!this.response.validation.success) {
            if (this.response.validation.errors['user.email']) {
              this.snack('warning', '이미 사용중인 이메일 입니다.')
              this.$refs.email.focus()
            }
          }
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    /* 구독된 cp의 카테고리만 가져오기 */
    postCategories () {
      let partners = this.config.linkPartners.map(function ($value) {
        return $value.id
      })
      let send = {
        partner: partners
      }
      this.xhttp({
        url: '/categories/show',
        method: 'post',
        data: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.category = response.data.data.categories
          this.categorySet()
          if (this.$route.name === 'mediaEdit') {
            this.categoryChecked()
          }
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    /* 카테고리 테이블에 넣을 값 만들기 */
    categorySet () {
      for (let i = 0; i < this.response.category.length; i++) {
        let category = {}
        category.header = [this.response.category[i]]
        category.header[0].value = 'name'
        category.header[0].text = category.header[0].name
        let children = []
        for (let j = 0; j < this.response.category[i].children.length; j++) {
          if (this.response.category[i].children[j].contents_count > 0) {
            children.push(this.response.category[i].children[j])
          }
        }
        category.selections = children
        this.config.acceptCategories.push(category)
      }
    },
    /* 저장된 카테고리값 체크하기 */
    categoryChecked () {
      let arr = this.response.media.acceptCategories
      let result = []
      for (let i = 0; i < arr.length; i++) {
        for (let j = 0; j < this.response.category.length; j++) {
          let adult = this.response.category[j].children.filter(function (n) {
            return n.id === arr[i]
          })
          if (adult.length > 0) {
            result.push(adult[0])
          }
        }
      }
      this.acceptCategoriesData = result
    },
    commissionPlaceholder () {
      if (this.vModel.data.rs_type === 'rs') {
        return '정산율'
      } else if (this.vModel.data.rs_type === 'cpm') {
        return 'CPM 단가'
      }
    },
  },
  computed: {
    managerPhoneMask () {
      let numberLength = this.vModel.data.company.managerPhone
      if (numberLength === 9) {
        return '####-####'
      } else if (numberLength === 11) {
        return '##-###-####'
      } else if (numberLength === 12) {
        return '###-###-####'
      } else {
        return '###-####-####'
      }
    },
    businessNoMask () {
      return '###-##-######'
    },
    mediaType () {
      if (this.$route.name === 'mediaEdit') {
        return '수정'
      } else {
        return '등록'
      }
    },
  },
  watch: {
    /* 카테고리가 체크될때 */
    acceptCategoriesData: function (val) {
      if (val) {
        this.vModel.data.acceptCategories = val.map(function ($value) {
          return $value.id
        })
      }
    }
  }
}
</script>

<style>
  #register .v-data-table__wrapper {
  border: 1px solid rgba(0, 0, 0, 0.12)
  }

  #register .v-data-table-header {
  background: #ddd
  }

  #register .category-list {
    cursor: pointer;
  }
  #register .category-list:hover {
    font-weight: bold;
  }
</style>
